import { loginRequest, graphConfig } from "../../config/msalConfig";
import { msalInstance } from "../../index";

import apiConfig from "../../config/api";

const API = {
  GetChatbotResponse: async (api, id, message, model, tone, references, sources, source_select, accessToken, tenantData, chatData, setMessage, setLoading, setReponseObj, setMessageComplete, updateAiResponse) => {
    if (!accessToken) {
      const account = msalInstance.getActiveAccount();
      if (!account) {
        throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
      }

      const response = await msalInstance.acquireTokenSilent({
        ...loginRequest,
        account: account
      });
      accessToken = response.accessToken;
    }

    const API_URL = `${apiConfig.ai[process.env.REACT_APP_API_ENV]}${process.env.REACT_APP_API_VER
      }`;

    const bearer = `Bearer ${accessToken}`;

    const today = new Date();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    const date = today.getDate();
    const currentDate = date + "-" + month + "-" + year;
    var jsonResponse = {}
    var raw = JSON.stringify({
      "tid": tenantData.tid,
      "oid": "",
      "date": currentDate,
      "session_id": chatData.session_id,
      "question_id": chatData.id,
      "model": `${model}`,
      "question": `${message}`,
      "references": references,
      "tone": `${tone}`,
      "sources": sources,
      "source_select": source_select
    });

    if (api == "simplify") {
      console.log("API Message", message);
      console.log("API Model", model);
      console.log("API Tone", tone);
      console.log("API References", references);
      console.log("API Source", source_select);

      var bot_response = ""
      var fetchURL = `${API_URL}/ai/ask_question`

      await fetch(fetchURL, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Authorization": bearer,
          "streaming": "True"
        },
        body: raw
      })
        .then(response => response.body)
        .then(async (result) => {
          const reader = result.getReader();
          const decoder = new TextDecoder('utf-8');

          while (true) {
            setLoading(false)
            const { done, value } = await reader?.read();
            var runningText = ""
            const responseString = new TextDecoder().decode(value);
            const objects = responseString.split("\n");

            for (const obj of objects) {
              console.log(obj)
              try {
                runningText += obj;
                if (runningText == [] || runningText == "") {
                  console.log("Running Text is empty")
                } else {
                  jsonResponse = JSON.parse(runningText);
                  updateAiResponse(jsonResponse.response, id)
                  setMessage(jsonResponse.response)
                  runningText = "";
                }
              } catch (e) {
                console.log(e)
                // Not a valid JSON object
              }
            }
            console.log("OBJ RESPONSE")
            console.log(jsonResponse)
            setReponseObj(jsonResponse)

            if (done) {
              setMessageComplete(true)
              return;
            }
          }
        })
        .catch((error) => {
          console.log("Error", error);
          bot_response = "There was an error"
        })

      return jsonResponse;
    } else if (api == "normal") {
      console.log("API Message", message);
      console.log("API Model", model);
      console.log("API Tone", tone);
      console.log("API References", references);
      console.log("API Source", source_select);

      var bot_response = ""
      var fetchURL = `${API_URL}/ai/ask_question`

      await fetch(fetchURL, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Authorization": bearer,
          "streaming": "True"
        },
        body: raw
      })
      .then(response => response.json())
      .then((result) => {
        if(typeof result === 'object') {
          console.log("SOURCE RESPONSE")
          console.log(result)
          setLoading(false)
          setMessage(result.response)
          setReponseObj(result)
          setMessageComplete(true)
          bot_response = result
        } else {
          console.log("NO SOURCE RESPONSE")
          console.log(result)
          setLoading(false)
          setMessage(result)
          setReponseObj(result)
          setMessageComplete(true)
          bot_response = result
        }
      })
      .catch((error) => {
        console.log("Error", error);
        bot_response = "There was an error"
      })

      return bot_response;
    }
  },
  CreateTitleContext: async (accessToken, tenantData, chatData) => {
    if (!accessToken) {
      const account = msalInstance.getActiveAccount();
      if (!account) {
        throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
      }

      const response = await msalInstance.acquireTokenSilent({
        ...loginRequest,
        account: account
      });
      accessToken = response.accessToken;
    }

    const API_URL = `${apiConfig.ai[process.env.REACT_APP_API_ENV]}${process.env.REACT_APP_API_VER
      }`;

    const bearer = `Bearer ${accessToken}`;

    var jsonResponse = {}
    var raw = JSON.stringify({
      "tid": tenantData.tid,
      "oid": "",
      "session_id": chatData.session_id,
      "question_id": chatData.id,
    });

    var bot_response = ""
    var fetchURL = `${API_URL}/ai/chat_context`

    await fetch(fetchURL, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Authorization": bearer,
      },
      body: raw
    })
      .then(response => response.body)
      .then(async (result) => {
        console.log(result);
      })
      .catch((error) => {
        console.log("Error", error);
        bot_response = "There was an error"
      })

    return jsonResponse;
  },
  UploadFiles: async (formData, accessToken, tenantData, session_id) => {
    if (!accessToken) {
      const account = msalInstance.getActiveAccount();
      if (!account) {
        throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
      }

      const response = await msalInstance.acquireTokenSilent({
        ...loginRequest,
        account: account
      });
      accessToken = response.accessToken;
    }

    const API_URL = `${apiConfig.ai[process.env.REACT_APP_API_ENV]}${process.env.REACT_APP_API_VER
      }`;

    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    formData.append("data", JSON.stringify({
      "tid": tenantData.tid,
      "session_id": session_id
    }))

    console.log("API FormData", formData);

    var bot_response = ""
    var fetchURL = `${API_URL}/system/upload_file`

    var raw = formData

    await fetch(fetchURL, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Authorization": bearer
      },
      redirect: "follow",
      body: raw
    })
      .then(response => response.text())
      .then((result) => {
        bot_response = result
        console.log(bot_response);
      })
      .catch((error) => {
        console.log("Error", error);
        bot_response = "There was an error"
      })

    return bot_response;
  },
  SubmitFeedback: async (name, email, type, feedback, chatHistory) => {
    const API_URL = `${apiConfig.ai[process.env.REACT_APP_API_ENV]}${process.env.REACT_APP_API_VER
      }`;

    console.log("API Feedback Name", name);
    console.log("API Feedback Email", email);
    console.log("API Feedback Type", type);
    console.log("API Feedback Feedback", feedback);
    console.log("API Feedback History", chatHistory);

    var bot_response = ""
    var fetchURL = `${API_URL}/submit_feedback`

    var raw = JSON.stringify({
      "name": `${name}`,
      "email": `${email}`,
      "type": `${type}`,
      "feedback": `${feedback}`,
      "chatHistory": chatHistory
    });

    await fetch(fetchURL, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*"
      },
      body: raw
    })
      .then(response => response.text())
      .then((result) => {
        bot_response = result
        console.log(bot_response);
      })
      .catch((error) => {
        console.log("Error", error);
        bot_response = "There was an error"
      })

    return bot_response;
  }
}

export default API
